<template>
  <div class="scroll-btn">
    <transition name="fade">
      <v-btn
        class="scroll-btn__btn"
        :class="{ 'scroll-btn__btn--up': showBottomBarActions }"
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        :color="ownerColors.ownerPrimaryColor"
        style=""
        @click="toTop"
      >
        <v-icon :color="ownerColors.ownerOnPrimaryColor"
          >keyboard_arrow_up</v-icon
        >
      </v-btn>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/flit2go-web-core/util/event-bus.js";

export default {
  name: "scroll-up-btn",
  data() {
    return {
      fab: false,
      showBottomBarActions: false,
    };
  },
  created() {
    EventBus.$on("actions", (value) => {
      this.showBottomBarActions =
        null !== value && value.buttons && value.buttons.length > 0;
    });
  },
  computed: {
    ...mapGetters({
      ownerColors: "auth/getOwnerColors",
    }),
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-btn {
  
  &__btn {
    margin-right: 6px;

    &--up {
      bottom: 150px;
    }
    
  }
}
</style>
